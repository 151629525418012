/* eslint-disable camelcase */
import Dropzone from 'dropzone'
window.Dropzone = Dropzone
Dropzone.autoDiscover = false

window.dropzone_config = function (form_id, maxFilesize) {
  new Dropzone(form_id, {
    url: document.querySelector(form_id).action,
    autoProcessQueue: false,
    parallelUploads: 1,
    maxFiles: 1,
    previewsContainer: document.querySelector(form_id + ' .previewsContainer'),
    clickable: '.dropzone-previews',
    addRemoveLinks: true,
    maxFilesize: maxFilesize / Math.pow(1024, 2), // MB
    filesizeBase: 1024,

    // The setting up of the dropzone
    init: function () {
      var myDropzone = this
      this.element.addEventListener('submit', function (e) {
        if (myDropzone.files.length) {
          /* dropzone will submit the whole form if it contains at least one file*/
          // First change the button to actually tell Dropzone to process the queue.
          document
            .querySelector('#create-secret [type=submit]')
            .setAttribute('disabled', '')
          e.preventDefault()
          e.stopPropagation()
          myDropzone.processQueue()
        }
      })

      this.on('addedfile', function (file) {
        if (this.files.length > 1) {
          this.removeFile(file)
          alert('Cannot add more than one file')
        } else {
          if (file.size > maxFilesize) {
            this.removeFile(file)
            alert(
              `File is too big (${
                Math.round(file.size / 1024 / 10.24) / 100
              } MB). Maximum file size: ${this.options.maxFilesize} MB.`,
            )
          }
        }
      })

      this.on('success', function (files, response) {
        var form = this.element
        var thank_you = document.querySelector(form_id + ' + .thank_you')

        // Update the thank you message and display it
        var link = document.querySelector('#link')
        var clipboard_text = document.querySelector('[data-clipboard-text]')
        var clipboard_link = document.querySelector('[data-clipboard-text] + a')
        link.innerHTML = response.data.link
        clipboard_text.setAttribute('data-clipboard-text', response.data.link)
        clipboard_link.href += response.data.link
        var valid_time = document.querySelector('.valid_until')
        valid_time.innerHTML = response.data.date_format
        form.classList.add('hide')
        thank_you.classList.remove('hide')
      })

      this.on('error', function (files, response) {
        let i
        document
          .querySelector('#create-secret [type=submit]')
          .removeAttribute('disabled')
        const old_errors = this.element.querySelectorAll('.field-error')
        if (old_errors.length) {
          old_errors.forEach(function (item) {
            item.parentNode.removeChild(item)
          })
        }
        let errors
        if (typeof response === 'object') {
          errors = JSON.parse(JSON.stringify(response))
        }
        for (const key in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, key)) {
            const field_errors = errors[key]
            const error_msg = document.createElement('div')
            error_msg.className =
              'state state-closed field-error alert alert-danger mt-2'
            for (i = 0; i < field_errors.length; i++) {
              const error_item = document.createElement('text')
              error_item.innerText = field_errors[i].message
              error_msg.appendChild(error_item)
            }
            const invalid_field = this.element.querySelector(
              '[name=' + key + ']',
            )
            invalid_field.insertAdjacentElement('afterend', error_msg)
          }
        }
        if (errors && !Object.prototype.hasOwnProperty.call(errors, 'file')) {
          const file_error = document.querySelector('.dz-error-message')
          if (file_error) {
            file_error.parentNode.removeChild(file_error)
          }
        }
        for (i = 0; i < this.files.length; i++) {
          /* queue again */
          this.files[i].status = Dropzone.QUEUED
          this.files[i].previewElement.classList.remove(
            'dz-complete',
            'dz-processing',
            'dz-success',
            'dz-error',
          )
        }
      })
    },
  })
}
